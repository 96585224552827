<template>
  <datetime
    v-model="date"
    type="datetime"
    input-class="focus:ring-indigo-500 focus:border-indigo-500 w-full pr-10 fs-18 b border-gray-300 rounded-md p-3 h-50p shadow-lg text-secondary"
    :placeholder="$t('Select Date')"
    format="dd.MM.yy HH:mm"
    aria-label="Date Picker"
    :min-datetime="minDatetime()"
    @focus="change"
    required
  >
  </datetime>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

export default {
  name: "DatePicker",
  components: { Datetime },
  props: ["deliveryType", "restaurantInfo"],
  data() {
    return {
      date: "",
    };
  },
  methods: {
    change() {
      this.date = "";
    },
    minDatetime() {
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      var h = null;
      if (this.deliveryType == "delivery")
        h = this.restaurantInfo.delivery_hours;
      else if (this.deliveryType == "here") h = this.restaurantInfo.here_hours;
      else if (this.deliveryType == "pickup")
        h = this.restaurantInfo.pickup_hours;
      return new Date().addHours(h).toISOString();
    },
  },
  computed: {
    UTCdate() {
      const date = new Date(this.date);
      return (
        date.getFullYear() +
        "-" +
        (date.getUTCMonth() + 1) +
        "-" +
        date.getUTCDate()
      );
    },
    UTCtime() {
      const date = new Date(this.date);
      return (
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes()
      );
    },
  },
  updated() {
    this.$emit("passDate", this.UTCdate);
    this.$emit("passTime", this.UTCtime);
  },
  mounted() {
    Settings.defaultLocale = this.$i18n.locale;
  },
};
</script>

<style>
.vdatetime-popup__header {
  background: var(--primary-color) !important;
}
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: var(--gray) !important;
  font-weight: bold !important;
}
.vdatetime-popup__actions__button {
  color: var(--primary-color) !important;
}
.vdatetime-popup__year {
  font-weight: bold !important;
}
.vdatetime-month-picker__item {
  font-size: 18px !important;
}
.vdatetime-month-picker__item--selected {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
  font-size: 26px !important;
}
.vdatetime-time-picker__item {
  font-size: 18px !important;
}
.vdatetime-time-picker__item--selected {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
  font-size: 26px !important;
}
.vdatetime-overlay {
  display: block !important;
  border-radius: 1.5rem !important;
}
.vdatetime-popup {
  border-radius: 1.5rem !important;
}
.vdatetime-popup__header {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.vdatetime-popup__year,
.vdatetime-popup__date {
  color: #000;
}
.vdatetime ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px !important;
  font-weight: normal !important;
}
.vdatetime ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px !important;
  font-weight: normal !important;
}
.vdatetime :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px !important;
  font-weight: normal !important;
}
.vdatetime :-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
