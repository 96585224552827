<template>
  <div class="subway_sixinch">
    <SideNav />
    <div class="flex flex-col w-full px-5 xl:px-20 prod-area pb-20 md:pb-32">
      <div class="relative py-12 w-full xl:w-9/12 mx-auto min-container">
        <div class="min-h-screen cart-area sb-radio">
          <div class="py-10 radio-check-switch">
            <form
              class="flex flex-col lg:flex-row jcb w-full mx-auto"
              @submit.prevent="onSubmit"
            >
              <div class="flex flex-col w-full px-6 lg:w-7/12">
                <div class="bg-white px-5 py-6 shadow-md lg:shadow-none">
                  <div class="flex flex-col border-b-2 border-dashed border-gray-100 pb-6">
                    <div class="flex jcb aic mb-5">
                      <h2 class="text-sm md:text-base text-primary">
                        {{ $t("Summary") }} ( {{ counter }} {{ $t("items") }} )
                      </h2>
                      <router-link
                        to="/dashboard/zusatzbestellung"
                        type="button"
                        class="flex aic jce a-dark no-btn"
                      >
                        <!-- <span class="hidden md:block">{{$t("Edit")}}</span> -->
                        <span class="flex aic jcc text-primary bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </span>
                      </router-link>
                    </div>
                    <div class="flex flex-col ais jcc">
                      <div
                        class="flex ais jcb fs-13 mr-8 mb-3 w-full"
                        v-for="(product, index) in ShoppingCart"
                        :key="product.id"
                      >
                        <span class="fs-12 text-primary b">{{ index + 1 }}.
                        </span>
                        <span class="flex w-full aic text-gray-600 mr-auto ml-2 capitalize">{{ product.name }}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3 w-3 mx-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <small>{{ product.quantity }}</small>
                        </span>
                        <span class="subway_footlong ml-1 ml whitespace-nowrap">{{ currency }} {{ product.totalPrice }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full space-y-4 pb-10 border-b-2 border-dashed border-gray-100">
                    <div class="flex jcb aic mt-10 mb-2">
                      <h2 class="text-sm md:text-base text-primary">
                        {{ $t("Delivery Methods") }}
                      </h2>
                    </div>
                    <div class="flex flex-col items-start space-y-4">
                      <div
                        class="fs-13 flex"
                        v-for="(method, i) in deliveryTypes"
                        :key="i"
                      >
                        <label
                          :for="method"
                          class="cursor-pointer flex aic"
                        >
                          <input
                            type="radio"
                            :id="method"
                            :name="method"
                            v-model="deliveryType"
                            :value="method"
                          />
                          <div class="radio-switch-state">
                            <span class="icon-on"></span>
                          </div>
                          <span class="fs-14 capitalize ml-3">{{
                            $t(method)
                          }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div :class="
                      showSelectTable
                        ? 'pb-10 border-b-2 border-dashed border-gray-100'
                        : ''
                    ">
                    <div
                      class="flex flex-col w-full space-y-4"
                      v-if="table && deliveryType == 'here'"
                    >
                      <div class="flex jcb aic mt-10">
                        <h2>{{ $t("Your Table") }}</h2>
                      </div>
                      <div class="flex flex-col md:flex-row w-full jcb">
                        <div class="w-full md:w-8/12 flex aic uppercase fs-22 subway_footlong text-primary px-5 h-60p bg-gray-100 mb-6 md:mb-0">
                          {{ table.name }}
                        </div>
                        <button
                          type="button"
                          @click="showSelectTable = !showSelectTable"
                          class="flex aic jce w-full md:w-4/12"
                          v-if="showSelectTable"
                        >
                          <span>{{ $t("Cancel") }}</span>
                          <div class="flex aic jcc text-primary-dark bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </button>
                        <div
                          class="flex justify-between w-full md:w-4/12"
                          v-else
                        >
                          <button
                            type="button"
                            @click="loadTable"
                            v-if="!qrcode"
                            class="flex aic jce whitespace-nowrap w-full"
                          >
                            {{ $t("change table") }}
                            <span class="flex aic jcc text-primary bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-col w-full space-y-4 animate__animated animate__fadeIn"
                      v-if="showSelectTable"
                    >
                      <div class="flex flex-col w-full">
                        <h2 class="pt-6">{{$t('Please select your table')}}</h2>
                        <div class="flex flex-col sm:flex-row w-full aic jcb space-y-4 sm:space-y-0 mt-4">
                          <div class="w-full sm:w-8/12 flex justify-between pr-3 border border-gray-100 shadow-md h-60p cSelect relative">
                            <select
                              id="selectedTable"
                              name="selectedTable"
                              v-model="selectedTable"
                              required
                              class="w-full px-5 h-60p flex aic uppercase bg-transparent text-primary-dark "
                            >
                              <option
                                v-for="(table, id) in tables"
                                :key="id"
                                :value="table.id"
                              >
                                {{ table.name }}
                              </option>
                            </select>
                          </div>
                          <button
                            type="button"
                            @click="setTable"
                            class="m-1 w-full sm:w-3/12 btn-green h-60p px-16 order-1 md:order-2"
                          >
                            {{ $t("Set") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col w-full mt-10 space-y-4 pb-10 border-b-2 border-dashed border-gray-100"
                    v-if="deliveryType == 'delivery'"
                  >
                    <div class="flex flex-col mt-3 mb-5">
                      <h2 class="text-sm md:text-base text-primary">
                        {{ $t("Delivery Address") }}
                      </h2>
                      <p class="text-sm md:text-base text-gray-400 mt-4"><i
                          class="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>
                        {{userInfo.address}},{{userInfo.postal_code}}</p>
                      <!-- <button
                        type="button"
                        v-if="isLogin"
                        @click="showNewAddress = !showNewAddress"
                        class="flax aic jcb a-dark no-btn fs-14"
                      >
                        <div
                          class="flex aic"
                          v-if="!showNewAddress"
                        >
                          <span class="text-sm md:text-base">{{
                            $t("Add address")
                          }}</span>
                          <span class="flex aic jcc text-primary bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </span>
                        </div>
                        <div
                          class="flex aic"
                          v-else
                        >
                          <span>{{ $t("Cancel") }}</span>
                          <div class="flex aic jcc text-primary-dark bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </button> -->
                    </div>
                    <!-- <transition enter-active-class="animate__animated  animate__fadeIn">
                      <div
                        class="flex flex-wrap pb-8"
                        v-if="showNewAddress"
                      >
                        <div class="w-full lg:w-1/2 lg:pr-2 pb-4 lg:pb-0">
                          <div class="mt-1">
                            <input
                              type="text"
                              id="City"
                              required
                              v-model="address.city"
                              class="w-full shadow-md px-5 h-60p lg:py-6 border border-gray-100"
                              placeholder="City"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-1/2 lg:pl-3">
                          <div class="mt-1">
                            <input
                              type="text"
                              id="Postal_code"
                              required
                              v-model="userInfo.postalCode"
                              class="w-full shadow-md px-5 h-60p lg:py-6 border border-gray-100"
                              placeholder="Postal code"
                              @blur="getDeliveryCost"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-9/12 lg:pr-2 pt-4 pb-4 lg:pb-0">
                          <div class="mt-1">
                            <input
                              id="address"
                              type="text"
                              required
                              v-model="address.address"
                              class="w-full shadow-md h-60p px-5 lg:py-6 border border-gray-100"
                              placeholder="Address"
                            />
                          </div>
                        </div>
                        <div
                          class="w-full lg:w-3/12 flex lg:pl-3"
                          v-if="isLogin"
                        >
                          <button
                            type="button"
                            @click="addUserAddress"
                            class="mt-1 self-end btn-green px-8 h-60p w-full order-1 md:order-2"
                          >
                            {{ $t("Add") }}
                          </button>
                        </div>
                      </div>
                    </transition>
                    <div
                      class="flex flex-col space-y-3"
                      v-if="isLogin"
                    >
                      <div
                        class="fs-13"
                        v-for="(address, i) in userAddress"
                        :key="i"
                      >
                        <label
                          :for="address.id"
                          class="cursor-pointer flex aic"
                        >
                          <input
                            type="radio"
                            :id="address.id"
                            name="address1"
                            v-model="selectedAddress"
                            :value="{
                              postal_code: address.postal_code,
                              address: address.address,
                            }"
                          />
                          <div class="radio-switch-state">
                            <span class="icon-on"></span>
                          </div>
                          <span class="fs-14 capitalize ml-3">{{
                            `${address.address}, ${address.postal_code}, ${address.city}`
                          }}</span>
                        </label>
                      </div>
                    </div> -->
                  </div>
                  <div class="flex flex-col w-full mt-10 space-y-4 pb-10 border-b-2 border-dashed border-gray-100">
                    <div class="flex jcb aic mt-3 mb-5">
                      <h2 class="fs-16 text-primary capitalize">
                        {{ $t("personal Information") }}
                      </h2>
                    </div>
                    <div class="flex flex-wrap w-full space-y-4">
                      <div class="w-full">
                        <div class="mt-1">
                          <input
                            id="full_name"
                            type="text"
                            :required="deliveryType != 'here'"
                            v-model="userInfo.name"
                            class="w-full shadow-md h-60p px-5 lg:py-6 border border-gray-100"
                            :placeholder="$t('Full Name')"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col md:flex-row aic w-full space-y-4 md:space-y-0 md:space-x-6">
                        <div class="w-full md:w-1/2">
                          <div class="mt-1">
                            <input
                              type="email"
                              id="email"
                              :required="deliveryType != 'here'"
                              v-model="userInfo.email"
                              class="w-full shadow-md h-60p px-5 lg:py-6 border border-gray-100"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div class="w-full md:w-1/2">
                          <div class="mt-1">
                            <input
                              id="mobile"
                              type="tel"
                              inputmode="tel"
                              pattern="[0-9]+"
                              name="phone"
                              :required="deliveryType != 'here'"
                              v-model="userInfo.mobile"
                              class="w-full shadow-md h-60p px-5 lg:py-6 border border-gray-100"
                              :placeholder="$t('Mobile')"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="w-full">
                        <div class="mt-1">
                          <textarea
                            id="comment"
                            v-model="userInfo.comment"
                            class="w-full shadow-md px-5 lg:py-6 border border-gray-100"
                            :placeholder="$t('Comment')"
                            rows="10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-5/12 xl:w-4/12 px-6 lg:pl-5 xl:pl-10 lg:border-l-2 border-dashed">
                <div class="bg-white px-5 py-12 shadow-md lg:shadow-none">
                  <div class="flex justify-between">
                    <div class="flex flex-col w-full space-y-4 pb-10 border-b-2 border-dashed border-gray-100">
                      <div class="flex jcb aic">
                        <h2 class="fs-16 text-primary capitalize mb-4">
                          {{ $t("Payment Method") }}
                        </h2>
                      </div>
                      <div class="flex flex-col items-start space-y-4 w-full">
                        <div
                          class="fs-13"
                          v-for="(method, i) in paymentMethods"
                          :key="i"
                        >
                          <label
                            :for="method"
                            class="cursor-pointer flex aic"
                          >
                            <input
                              type="radio"
                              :id="method"
                              :name="method"
                              v-model="payment"
                              :value="method"
                            />
                            <div class="radio-switch-state">
                              <span class="icon-on"></span>
                            </div>
                            <span class="fs-14 capitalize ml-3">
                              {{ $t(method) }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="deliveryType != 'here'"
                    class="flex flex-col w-full mt-10 space-y-4"
                  >
                    <div class="flex jcb aic mt-3 mb-5 relative">
                      <h2 class="fs-14 flex flex-col md:flex-row items-start md:items-center">
                        <span class="hidden md:block text-primary mb-5 md:mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 mr-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                        <span class="fs-16 text-primary subway_footlong"><span class="text-gray-900 subway_sixinch fs-14 mr-1">{{ $t("Deliver at") }}</span>
                          {{ deliveryTime || $t("as soon as possible") }}</span>
                      </h2>
                      <button
                        v-if="restaurantInfo.pre_order"
                        type="button"
                        @click="showSchedule = !showSchedule"
                        class="flax aic jcb a-dark no-btn fs-14"
                      >
                        <div class="flex aic">
                          <!-- <span class="hidden md:block">{{$t("Edit")}}</span> -->
                          <span
                            v-if="!showSchedule"
                            class="flex aic jcc text-primary bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                          </span>
                          <div
                            v-else
                            class="flex aic jcc text-primary-dark bg-gray w-35p h-35p ml-3 animate__animated animate__fadeIn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <transition enter-active-class="animate__animated  animate__fadeIn">
                    <div v-if="showSchedule">
                      <form
                        @submit.prevent="changeDate"
                        class="flex flex-col w-full border-b-2 py-4 space-y-7"
                      >
                        <div class="w-full">
                          <label
                            for="date"
                            class="block text-sm font-medium text-gray-600"
                          >{{ $t("Date") }}</label>
                          <div class="mt-1 relative rounded-md border">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <i class="fa fa-calendar text-gray-400"></i>
                            </div>
                            <date
                              @passDate="date = $event"
                              :deliveryType="deliveryType"
                              :restaurantInfo="restaurantInfo"
                              @passTime="time = $event"
                            ></date>
                          </div>
                        </div>
                        <div class="flex w-full">
                          <button
                            type="submit"
                            class="self-end btn-green px-3 py-1 h-40p w-full order-1 md:order-2 h-50p"
                          >
                            {{ $t("Set") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </transition>
                  <div class="flex w-full jce">
                    <div class="flex flex-col w-full">
                      <div class="flex flex-col mt-8 mb-5">
                        <!-- <div class="sm:col-span-2 mb-5">
                          <div class="flex jcb aic mt-1 w-full shadow-md border border-gray-100 h-50p">
                            <input
                              type="text"
                              name="voucher"
                              id="voucher"
                              v-model="voucher"
                              :readonly="validVoucher"
                              class="h-50p px-4 block w-10/12 border-0 bg-transparent"
                              :placeholder="$t('Voucher')"
                            />
                            <button
                              type="button"
                              v-if="!validVoucher"
                              @click="addVoucher"
                              class="w-50p h-50p btn-green-light"
                            >
                              <i class="fa fa-check text-white fs-20"></i>
                            </button>
                            <button
                              v-else
                              type="button"
                              @click="removeVoucher"
                              class="w-50p h-50p"
                            >
                              <i class="fa fa-times text-red-500"></i>
                            </button>
                          </div>
                        </div> -->
                        <div class="flex flex-col border-b-2 border-dashed pb-5 mt-16 mb-5 border-gray-100">
                          <span class="flex aic jcb tracking-wide fs-14 mb-5">
                            <span class="text-gray-400">{{
                              $t("Shipping")
                            }}</span>
                            <b>{{ currency }} {{ shippingPrice }}</b>
                          </span>
                          <!-- <span class="flex aic jcb tracking-wide fs-14">
                            <span class="text-gray-400">{{
                              $t("Discount")
                            }}</span>
                            <b>{{ currency }} {{ VoucherValue }}</b>
                          </span> -->
                        </div>
                        <span class="flex aic jcb tracking-wide fs-14">
                          <span class="text-gray-400">{{
                            $t("Total Price")
                          }}</span>
                          <b>{{ currency }} {{ totalWithDiscount }}</b>
                        </span>
                      </div>
                      <button
                        class="btn-green px-3 py-3 w-full"
                        type="submit"
                      >
                        {{ $t("Pay") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Date from "../components/Date.vue";
import SideNav from "@/components/SideNav.vue";

export default {
  components: { Date, SideNav },

  data() {
    return {
      payment: "cash",
      date: null,
      type: null,
      showSchedule: false,
      showNewAddress: true,
      selectedAddress: null,
      deliveryType: "delivery",
      address: { country: "Germany" },
      userInfo: {},
      selectedTable: null,
      showSelectTable: false,
      showConfirm: false,
      msg: "",
      status: "",
      validVoucher: false,
      voucher: null,
    };
  },
  computed: {
    qrcode() {
      return this.$store.state.orderiomApiPackage.product.basketInfo.qrcode;
    },
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
    basketId() {
      if (!localStorage.getItem("privateToken")) {
        const basket = JSON.parse(localStorage.getItem("basket"));
        const basketId = basket.find(
          (basket) => basket.restaurantId == this.restaurantId
        ).basketId;
        return basketId;
      } else return null;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    voucherCode() {
      return this.$store.state.orderiomApiPackage.product.voucherCode;
    },
    isLogin() {
      return this.$store.state.orderiomApiPackage.auth.privateToken;
    },
    totalWithDiscount() {
      return (
        Number(this.$store.state.orderiomApiPackage.product.totalWithDiscount) +
        Number(this.shippingPrice)
      ).toFixed(2);
    },
    paymentMethods() {
      // return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
      //   .paymentMethods;
      return ["cash"];
    },
    deliveryTypes() {
      return [process.env.VUE_APP_DELIVERY];
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
    VoucherValue() {
      return this.$store.state.orderiomApiPackage.product.VoucherValue;
    },
    counter() {
      let quantity = this.ShoppingCart.map((m) => {
        return m.quantity;
      });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    shippingPrice() {
      const price = +this.$store.state.orderiomApiPackage.order.shippingPrice;
      return price.toFixed(2);
    },
    userAddress() {
      return this.$store.state.orderiomApiPackage.auth.userAddress;
    },
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
    tables() {
      return this.$store.state.orderiomApiPackage.restaurant.tables;
    },
    table() {
      return this.$store.state.orderiomApiPackage.product.basketInfo.table;
    },
    deliveryTime() {
      let deliveryTime =
        this.$store.state.orderiomApiPackage.product.deliveryTime;
      if (deliveryTime) {
        let date = deliveryTime
          ? deliveryTime.split(" ")[0].split("-").reverse().join(".")
          : "";
        let time = deliveryTime
          ? deliveryTime.split(" ")[1].split(":").slice(0, 2).join(":")
          : "";

        return `${date} , ${time}`;
      } else return null;
    },
  },
  watch: {
    user: {
      deep: true,
      handler: function (newValue) {
        if (localStorage.getItem("privateToken")) {
          this.userInfo = { ...newValue, ...this.userInfo };
          this.userInfo.postalCode = this.user.postal_code;
          this.showDeliveryType = true;
          this.$store.dispatch("order/getShippingPrice", {
            postalCode: this.userInfo.postalCode,
            deliveryType: this.deliveryTypes[0],
            restaurantId: this.restaurantId,
          });
        }
      },
    },
  },
  methods: {
    addVoucher() {
      if (!this.voucher) {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: "Please enter a valid code",
          type: "error",
        });
        return;
      }
      this.$store
        .dispatch("order/addVoucher", {
          restaurantId: this.restaurantId,
          voucherCode: this.voucher,
        })
        .then((res) => {
          this.$store.commit("setshowNotif", {
            show: true,
            msg:
              res.type == "success"
                ? "Your voucher code has added to your basket."
                : res.msg,
            type: res.type,
          });
          if (res.type == "success") {
            this.validVoucher = true;
          }
        });
    },
    removeVoucher() {
      this.$store
        .dispatch("order/removeVoucher", this.restaurantId)
        .then((res) => {
          if (res.type == "success") {
            this.validVoucher = false;
          }
        });
    },
    loadTable() {
      this.showSelectTable = true;
    },
    setTable() {
      if (this.status == "new") {
        this.$store.commit("setTable", this.selectedTable);
      } else
        this.$store
          .dispatch("order/setTable", {
            restaurantId: this.restaurantId,
            tableId: this.selectedTable,
            qrcode: 0,
          })
          .then(() => {
            this.$store.dispatch("product/getBasket", this.restaurantId);
            this.showSelectTable = false;
          });
    },

    // addNewTime() {
    //   Date.prototype.addHours = function (h) {
    //     this.setTime(this.getTime() + h * 60 * 60 * 1000);
    //     return this;
    //   };
    //   var dateNow = new Date().addHours(1);
    //   let date = `${dateNow.getFullYear()}-${
    //     dateNow.getMonth() + 1
    //   }-${dateNow.getDate()}`;
    //   let time = `${dateNow.getHours().toString().padStart(2, "0")}:${dateNow
    //     .getMinutes()
    //     .toString()
    //     .padStart(2, "0")}`;
    //   return this.$store
    //     .dispatch("order/addDeliveryTimeToBasket", {
    //       time: time,
    //       date: date,
    //       restaurantId: this.restaurantId,
    //     })
    //     .then((res) => {
    //       this.$store.commit("product/setDeliveryTime", `${date} ${time}`);
    //       if (res.type == "error") {
    //         this.status = "error";
    //         this.msg = res.msg;
    //         this.showConfirm = true;
    //         setTimeout(() => {
    //           this.showConfirm = false;
    //         }, 3000);
    //       }
    //     });
    // },
    addUserAddress() {
      this.$store.dispatch("auth/addUserAddress", {
        country: this.address.country,
        city: this.address.city,
        address: this.address.address,
        postal_code: this.address.postalCode,
      });
      this.showNewAddress = false;
    },
    onSubmit() {
      if (this.deliveryType == "here" && !this.table) {
        this.status = "error";
        this.msg = "please choose a table";
        this.showConfirm = true;
        setTimeout(() => {
          this.showConfirm = false;
        }, 3000);
        return;
      }
      this.userInfo.deliveryType = this.deliveryType;
      if (this.isLogin) {
        this.userInfo.postalCode = this.userInfo.postal_code;
      } else {
        this.userInfo = { ...this.userInfo, ...this.address };
      }
      this.$store
        .dispatch("order/addAddress", {
          userInfo: this.userInfo,
          restaurantId: this.restaurantId,
        })
        .then((res) => {
          const accept = res;
          if (accept)
            this.$store
              .dispatch("order/checkAddress", {
                postalCode: this.userInfo.postalCode,
                deliveryType: this.deliveryType,
                restaurantId: this.restaurantId,
              })
              .then((res) => {
                if (res.type == "success") {
                  this.$store
                    .dispatch("order/createOrder", {
                      restaurantId: this.restaurantId,
                      basketId: this.basketId,
                      paymentType: this.payment,
                    })
                    .then((res) => {
                      if (res.type == "error") {
                        this.$store.commit("setshowNotif", {
                          show: true,
                          msg: res.msg,
                          type: res.type,
                        });
                      }
                    });
                } else {
                  this.$store.commit("setshowNotif", {
                    show: true,
                    msg: res.msg,
                    type: res.type,
                  });
                }
              });
        });
    },
    changeDate() {
      this.$store
        .dispatch("order/addDeliveryTimeToBasket", {
          time: this.time,
          date: this.date,
          deliveryType: this.deliveryType,
          restaurantId: this.restaurantId,
        })
        .then((res) => {
          if (res) {
            if (res.type == "error") {
              this.$store.commit("setshowNotif", {
                show: true,
                msg: res.msg,
                type: res.type,
              });
            } else {
              this.$store.commit(
                "product/setDeliveryTime",
                `${this.date} ${this.time}`
              );
              this.showSchedule = false;
            }
          }
        });
    },
    getDeliveryCost() {
      if (this.deliveryType == "delivery") {
        this.userInfo.deliveryType = this.deliveryType;
        this.$store
          .dispatch("order/getShippingPrice", {
            postalCode: this.userInfo.postalCode,
            deliveryType: this.userInfo.deliveryType,
            restaurantId: this.restaurantId,
          })
          .then((res) => {
            if (res.type == "error") {
              this.showConfirm = true;
              this.type = res.type;
              this.msg = res.msg;
            }
          });
        this.$store.dispatch("order/getTotalPrice", {
          postalCode: this.userInfo.postalCode,
          deliveryType: this.userInfo.deliveryType,
          restaurantId: this.restaurantId,
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("privateToken")) {
      this.showNewAddress = false;
      // this.$store.dispatch("auth/getUserAddress").then(() => {
      //   this.selectedAddress = {
      //     address: this.userAddress[0].address,
      //     postal_code: this.userAddress[0].postal_code,
      //   };
      // });
      this.$store.dispatch("auth/fetchUser").then(() => {
        this.userInfo = { ...this.user, ...this.userInfo };
        this.userInfo.postalCode = this.userInfo.postal_code;
      });
    }
    this.$store
      .dispatch("product/getBasket", localStorage.getItem("restaurantId"))
      .then(() => {
        if (Number(this.VoucherValue) > 0) {
          this.validVoucher = true;
          this.voucher = this.voucherCode;
        }
      });
  },
};
</script>
